@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100..700;1,100..700&family=Roboto+Slab:wght@100..900&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap'); */

:root {
    --primary: #7655ab;
    --secondary: #2B9BFF;
    --light: #EFFDF5;
    --dark: #2B3940;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    /* font-family: "Poppins", sans-serif; */
    font-family: 'Roboto Slab', 'Font Awesome 6 Free' !important;
    font-style: normal;
    font-weight: 100 700;
}

p {
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5 {
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none !important;
    color: #000 !important;
}

.main-header {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 25px;
}

.main-header .navbar-logo {
    width: 6.2rem;
    height: 3.1rem;
}

.main-header .navbar-logo img {
    width: 100%;
    height: 100%;
}

.login-sec {
    display: flex;
    margin-left: auto;
}

.login-left {
    display: flex;
    margin-left: auto;
    gap: 30px;
}

.login-left a {
    text-decoration: none;
    color: var(--primary) !important;
}

/* -------------------------------------login-form */
.login-form {
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    left: 50%;
    max-width: 550px;
    width: 550px;
    transform: translate(-50%, 50%);
}

.login-form.active {
    display: block;
}

.btn-login {
    color: #fff !important;
    background-color: var(--primary) !important;
    width: 100%;
}

.login-wrap h4 {
    text-align: center;
    color: var(--primary);
    font-weight: 700;
    font-size: 2.9rem;
}

.login-wrap p {
    color: #000000;
    font-weight: 500;
}

.login-form .close_login {
    float: right;
}

/* ----------------------------- Search Items css ///////............ */
.search-location {
    position: relative;
    width: 100%;
    max-width: 600px;
    transition: width 3s ease;
    overflow: hidden;
}

.search-location:focus-within #scroll-text {
    animation: none;
}

.login-left span {
    display: grid;
    text-align: center;
}

.nav-sec {
    background-color: #f8f5ff;
    transition: 0.7s;
    top: -100px;
    padding: 8px 3rem;
}

.nav-sec.sticky {
    position: sticky;
    top: 0;
    z-index: 99;
}

.location-header span {
    font-weight: 500;
    font-size: 0.82rem;
    cursor: pointer;
}

.location-header .cardbox {}

.main-header .navbar-brand {
    color: #7655ab;
    width: 90px;
    height: 53px;
    display: flex;
    align-items: center;
}

.main-header .navbar-brand img {
    width: 100%;
    height: 100%;
}

.login-left i {
    font-size: 1.3rem;
}

.search-location input {
    padding-left: 30px;
}

.search-location i {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-left: 10px;
    color: #7655ab;

}

.login-left i {
    color: #7655ab;
}
/* --------------------------------- wishlist --------- */
.wishlist-section .wishlist-img{
    width: 80px;
    height: 65px;
}
.wishlist-section .wishlist-trash{
     width: 60px;
     height: 50px;
}
.wishlist-section table tr td{
    vertical-align: middle;
}

.wish-list_section {
    position: relative;
    margin-right: 5px;
    margin-top: 5px;
}

.wish-list_section .wish-list-icon {
    color: var(--primary);
    font-size: 25px;
}

.wish-list_cart_box {
    position: absolute;
    right: -5px;
    top: -13px;
    background-color: #7655ab;
    color: #fff;
    width: 18px;
    height: 18px;
    display: flex !important;
    border-radius: 50%;
    justify-content: center;
    font-size: 11px;
    align-items: center;
}

.cart-wrap {
    position: relative;
    margin-right: 5px;
    margin-top: 5px;
}

.cart_box {
    position: absolute;
    right: -5px;
    top: -13px;
    background-color: #7655ab;
    color: #fff;
    width: 18px;
    height: 18px;
    display: flex !important;
    border-radius: 50%;
    justify-content: center;
    font-size: 11px;
    align-items: center;
}

.contact_back {
    /* background-image: url(..//src/assets/images/contact_back_size.jpg); */
    width: 100%;
    height: 100%;
}

.location-header {
    display: flex;
    white-space: nowrap;
}

.contact-form {
    background-color: #fcfcfc;
    padding: 20px;
    border-radius: 15px;
}

.contact-form .form-control {
    background-color: #F2F2F2 !important;
}

.contact-form h3 {
    color: #7655ab;
}

/* -----------------------    hero section-start================================== */
.hero-play img {
    width: 100%;
    height: 330px;
}

.fea-sec-top {
    font-weight: 700;
    margin-left: 5px;
}

.card-wrap .category-img {
    height: 7rem;
}

.category-img img {
    border-radius: 7px;
    width: 100%;
    height: 100%;
}

.card-wrapper-cate .card-wrap {
    text-align: center;
    margin-right: 15px;
    border-radius: 7px;
}

.card-wrapper-cate {
    flex-wrap: wrap;
}

.card-wrap p {
    font-size: 14px;
    font-weight: 500;
}

/*       -----------------------    products-sec-start  -------------------*/
.product-cart-item-name {
    width: 150px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.dz-img-box.style-4 {
    background: #fff;
    box-shadow: 0 2px 8px #4f4f4f26;
    border-radius: 10px;
    padding: 18px;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    transition: all .5s;
    display: flex;
    flex-direction: column;
    height: 160px;
    width: 306px;
}

.crt-plus {
    position: absolute;
    right: 0;
    bottom: 0;
}

.add-to-crt-wrap {
    display: none;
}

.icon-btn {
    font-size: 0.8rem;
    border: none;
    outline: none;
    background: none;
    display: flex;
    color: var(--primary);
}

.crt-plus .detail-btn {
    transition: 0.9s !important;
}

.crt-plus .add-to-cart {
    border: 1px solid #4d00ff;
    padding: 1px 12px;
    border-radius: 15px;
    align-items: center;
    max-width: 75px;
    float: inline-end;
    margin-bottom: 5px;
    margin-right: 4px;
}

.crt-plus .detail-btn {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    width: 48px !important;
    height: 35px !important;
    line-height: 37px !important;
    color: #fff;
    text-align: center;
    display: block;
    border-radius: 10px 0;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    transition: all .5s;
}

.dz-img-box {
    position: relative;
    overflow: hidden;
}

.dz-img-box-before::before {
    position: absolute;
    width: 100%;
    height: 100%;
    content: "";
    background-color: #ffffff8a;
    z-index: 98;
}

.dz-img-box.style-4 .menu-detail {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.dz-img-box.style-4 .menu-detail .dz-media {
    margin-right: 20px;
    width: 60px;
    height: 60px;
    min-width: 60px;
}

.dz-media img,
.dz-post-media img {
    width: 100%;
    height: 100%;
}

.dz-img-box.style-4 .menu-detail .dz-content p {
    font-size: 0.9rem;
    line-height: 21px;
    margin-bottom: 0;
    padding-top: 7px;
}

.dz-img-box.style-4 .menu-footer {
    max-width: 225px;
    margin-top: auto;
}

.link-primary_number {
    color: var(--primary);
}

.dz-img-box.style-4 .menu-footer span {
    font-weight: 500;
    font-size: 0.8rem;
    line-height: 30px;
}

.name-crt-bottom .price {
    color: var(--primary);
    margin-bottom: 0;
    transition: all .5s;
    font-size: 1.1rem;
    display: block;
    font-weight: 600;
    line-height: 1.2;
}

.dz-img-box.style-4 .menu-footer .price {
    color: var(--primary);
    margin-bottom: 0;
    transition: all .5s;
    font-size: 1.2rem;
    display: block;
    font-weight: 600;
    line-height: 1.2;
}

.price .price-without-discount {
    margin-left: 3px;
    font-size: 1.2rem !important;
}

.price .price-and-discount {
    margin: 0px 3px;
}

.dz-img-box.style-4 .detail-btn {
    background-color: var(--primary);
    width: 48px;
    height: 48px;
    line-height: 48px;
    color: #fff;
    text-align: center;
    display: block;
    position: absolute;
    bottom: 0;
    right: 0;
    border-radius: 10px 0;
    -webkit-transition: all .5s;
    -ms-transition: all .5s;
    transition: all .5s;
    border: 1px solid var(--primary);
    ;
}

.dz-content .title a {
    color: #000;
    text-decoration: none;
    font-weight: 600;
    font-size: 2.2rem;
}

/* =======- ------------------- slick-css-product-sec */

.slick-slider .slick-list .slick-track {
    display: flex;
    gap: 15px;
    
}

.slick-initialized .slick-slide {
    margin: 0 7x 0 8px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.slick-next,
.slick-prev {
    z-index: 5;
}

.slick-next {
    right: -4px !important;
}

.slick-prev {
    left: 2px !important;
}

.slick-next:before,
.slick-prev:before {
    color: #000;
    font-size: 2.6rem;
}

.slick-dots {
    display: none !important;
}

.slick-next:before,
.slick-prev:before {
    font-size: 2rem;
    line-height: 1;
    opacity: .75;
    color: #7655ab !important;
}

.my-slider .dz-img-box {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: 5px 8px;
}

.slick-next:before,
.slick-prev:before {
    font-size: 1.6rem !important;
    line-height: 1;
    opacity: .75;
    color: #fff;
}

/* -------------------------- cold-drink-juice-sec-start================ */
.my-slider-drinks .dz-img-box {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: 5px 8px;
}

/*--------------------------------- snacks-sec-start================ */
.my-slider-snacks .dz-img-box {
    position: relative;
    z-index: 1;
    overflow: hidden;
    margin: 5px 8px;
}

/*  ---------------------    React model location css */

.ReactModal__Content {
    border-radius: 10px !important;
    padding: 10px !important;
    font-weight: bold;
    z-index: 1;
}

.ReactModal__Content--after-open {
    overflow: hidden !important;
    background-color: #fff;
    padding: 6px 15px 0px 15px;
    width: 500px;
    max-width: 100%;
    /* height: 186px; */
    max-height: fit-content;
    border-radius: 5px;
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%);
    transition: 0s;
    z-index: 99;
}

.ReactModal__Overlay {
    background-color: rgba(0, 0, 0, 0.75) !important;
    margin-top: -70px;
    z-index: 9;
}

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000b3;
    z-index: 9;
    opacity: 0;
    pointer-events: none;
}

.cardbox .form-control {
    background-color: #c9bcee1c !important;
}

.location-header .icon-right {
    margin-bottom: 26px;
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
}

.closebtn {
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: #7655ab;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    margin-bottom: 15px;
}

.s-bottom {
    position: absolute;
    top: 20px;
    left: 36px;
    line-height: 34px;
    color: #999999;
    pointer-events: none;
}

.s-bottom-none {
    display: none;
}

.loc-gps {
    color: #ff009e;
}

.loc-gps small {
    margin-left: 20px;
}

.cardbox .form-control {
    background-color: #c9bcee1c !important;
}

.location-header .icon-right {

    margin-bottom: 26px;
    margin-top: 4px;
    display: flex;
    justify-content: space-between;
}

.location-header i {
    margin-left: 5px;
}

.login-wrap {
    margin-top: -60px;
}

/*  --------------------------------- login form css */

.close_login {
    cursor: pointer;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-color: #7655ab;
    color: #fff;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    float: right;
    position: relative;
    top: -60px;
}

.Contact_main_heading {
    background-image: url(../images/banner2.jpeg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 250px;
    margin-bottom: 20px;
    padding-top: 70px;
    text-align: center;
    position: relative;
    color: white;
}

.Contact_main_heading::before {
    content: '';
    width: 100%;
    height: 250px;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;

}

.Contact_main_heading .contact_head {
    position: relative;
}

.signup-login-form {
    max-width: 450px;
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.signup-login-form h3 {
    text-align: center;
    margin-bottom: 20px;
}

.form-group {
    margin-bottom: 20px;
    position: relative;
}

.form-group label {
    display: block;
    font-weight: bold;
}

.form-group input {
    width: 100%;
    padding: 10px;
    font-size: 0.9rem;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.btn-login {
    width: 100%;
    padding: 10px;
    font-size: 1.6rem;
    color: #fff;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-login:hover {
    background-color: #662eed !important;
}

.forgot-password button {
    color: #007bff;
    background-color: transparent;
    border: none;
    cursor: pointer;
    transition: color 0.3s ease;
    float: right;
    font-size: 14px;
    margin-bottom: 20px;
}

.forgot-password button:hover {
    color: #0056b3;
}

.already-signp-login-btn {
    background-color: var(--primary);
    border: 1px solid var(--primary);
    border-radius: 0.6em;
    color: white;
    cursor: pointer;
    align-self: center;
    padding: 5px 10px;
    margin-left: 20px;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;

    &:hover {
        color: #fff;
        outline: 0;
        background-color: #662eed;
    }
}


/* =========--------------------------- Breadcrunb css */
.Contact_main_heading .breadcrumb {
    justify-content: center;
    display: flex;
    z-index: 0;
    position: relative;
}

.Contact_main_heading .breadcrumb .breadcrumb-item a {
    color: white !important;
    text-decoration: none;
    transition: 0.5s all;
    font-weight: 600;
    font-size: 1rem;
}

.Contact_main_heading .breadcrumb .breadcrumb-item+.breadcrumb-item::before {
    color: white;
    font-size: 1rem;
    font-weight: 800;
}

.Contact_main_heading .breadcrumb a:hover {
    color: #16a085 !important;
}

.about_section_main_wrapper .about_head h3 {
    font-size: 1.remem;
    background: #ffde68;
    font-weight: 700;
    display: inline-block;
    padding: 10px;
}

/*  --------------   --------- SIGN-UP FORM CSS -------------------- */

.signup-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    animation: slideIn 0.5s ease;
}

.signup-form h3 {
    text-align: center;
    margin-bottom: 20px;
}

button[type="submit"] {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: var(--primary);
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

button[type="submit"]:hover {
    background-color: #662eed;
}

/* -------------------    Styles for OTP Verification Form     --------------*/
.otp-verification-form {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.otp-verification-form h2 {
    font-size: 2.4rem;
    text-align: center;
    margin-bottom: 20px;
}

/*   --------------------------     EYE BUTTON CSS      ----------- */
button.signup_eye_btn {
    color: #6e6c6c;
    position: absolute;
    background: initial;
    right: 9px;
}

.signup-login-form .form-group {
    position: relative;
}

.dont-account-signup-login a {
    color: var(--primary) ! IMPORTANT;
}

.dont-account-signup-login span {
    margin-left: 10px;
}

.signup_eye_btn {
    position: relative;
    border: none;
    font-size: 1.5rem;
}

/* ----------------------------- scroll-into searchbox- css */
#scroll-text {
    height: 100%;
    text-align: center;
    /* animation properties */
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-animation: my-animation 2s linear infinite;
    animation: my-animation 2s linear infinite;
}

.overlay_login {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #000000b3;
    z-index: 9;
    opacity: 0;
    pointer-events: none;
}

.overlay_login.active {
    opacity: 1;
}

/* --------------------     for Chrome */
@-webkit-keyframes my-animation {
    from {
        -webkit-transform: translateY(100%);
    }

    to {
        -webkit-transform: translateY(-100%);
    }
}

@keyframes my-animation {
    from {
        -moz-transform: translateY(100%);
        -webkit-transform: translateY(100%);
        transform: translateY(100%);
    }

    to {
        -moz-transform: translateY(-100%);
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%);
    }
}

/* ===================================== footer css footer start====================================== */

.footer {
    height: auto;
    background-color: #f9f6ff;
    border-top: 1px solid gainsboro;
}

.footer-category .main-list {
    list-style: none;
    padding: 0px;
    display: grid;
    grid-template-columns: auto auto;
    gap: 1px 10px;
}

.footer-category .footer-see-all a {
    color: rgba(0, 128, 0, 0.619) !important;
    font-size: 1rem !important;
    font-weight: 600;
}

.footer-category p {
    font-weight: 700;
    font-size: 1.2rem;
}

.main-list {
    list-style: none;
}

.main-list li {
    text-decoration: none;
    padding-bottom: 10px;
    font-weight: 400;
    margin-left: 10px;
}

.main-list li a {
    color: #000;
    list-style: none;
    text-decoration: none;
}

.main-list li a:hover {
    color: var(--primary) !important;
}

.main-list p {
    font-weight: 700;
    font-size: 1.2rem;
}

.top-c-list {
    font-weight: 700;
    font-size: 1.5rem;
}

.bottom-wrapper {
    text-align: center;
    border-top: 1px solid gainsboro;
}

.bottom-wrapper p {
    margin: 0 10px;
}

.bottom-line-footer {
    background-color: #f8f5ff;
}

/* ----------------------- about-css================= */
.primary-heading {
    color: var(--primary);
}

.card-wrap a {
    color: #000;
}

/*  ===========================    subcategory details list page css-start=  ==== ========= */

.categories-table__row .category-left-list {
    display: flex;
    padding: 7px;
    align-items: center;
    margin-bottom: 5px;
    border: 1px solid #f6f1ff;
}

.categories-table__row .subcategory-left-list-img img {
    width: 100%;
    height: 100%;
}

.categories-table__row .subcategory-left-list-img {
    width: 50px;
    height: 55px;
}

.categories-table__row .category-left-list p {
    margin-left: 5px;
    color: #000;
}

.categories-table__row .subcategory-sidebar .active {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border-left: 3px solid #7655ab;
    background-color: #f0e7fffc;
}

.categories-table__row {
    position: sticky;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(50vh);
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none;
} 

.cd-subcategory-item {
    height: 50vh;
    overflow-y: scroll;
    scrollbar-width: none;
}

/* ========= --------------------        product details page css=========== */
.product-title {
    position: relative;
    gap: 50px;
    align-items: baseline;
}

.add-to-cart {
    border: 1px solid #4d00ff;
    padding: 1px 12px;
    border-radius: 15px;
    display: flex;
    align-items: center;
}

.add-to-cart i {
    font-size: 1.3rem;
    color: #7655ab;
    font-weight: bold;
    cursor: pointer;
}

.right-cart {
    display: flex;
    align-items: center;
    gap: 15px;
}

.product-title p {
    color: #000;
    font-weight: 500;
}

.price-list p {
    color: var(--primary);
    font-weight: 500;
}

.price-list {
    color: var(--primary);
    font-weight: 500;
}

.breadcrumb-item a {
    color: #000 !important;
}

.product-left-image {
    position: sticky;
    top: 60px;
    left: 0;
    border: 1px solid #eadcff;
    border-radius: 5px;
    padding: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.product-main-img {
    width: 300px;
    height: 300px;
}

.product-left-image img {
    width: 100%;
    height: 100%;
}

.product-title .title-ptop {
    font-size: 1.2rem;
    display: flex;
    align-items: center;
    font-weight: bold;
    margin-bottom: 10px;
}

.product-title .title-ptop i {
    color: #7655ab;
    font-size: 1.2rem;

}

.product-title .title-ptop span {
    display: block;
    text-align: end;
    font-size: 0.8rem;
    color: #747474;
    white-space: nowrap;
    margin-bottom: 10px;
}

.img-slider img {
    max-width: 74px;
    margin-right: 0 20px;
}

.imagehover {
    display: flex;
    align-items: center;
}

.imagehover i {
    background-color: #f1e8ff;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    cursor: pointer;
}

.imagehover .left-arrow i:hover,
.imagehover .right-arrow i:hover {
    background-color: hwb(300 61% 9%);
    font-size: 1rem;
}

.img-slider {
    display: flex;
    width: 100%;
    overflow-y: auto;
    scrollbar-width: none;
    align-items: center;
    justify-content: space-between;
}

.img-slider .clickimg-div {
    width: 90px;
    height: 90px;
}

.clickimg-div .clickimg {
    width: 100%;
    height: 100%;
}

.img-slider .clickimg.active {
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.img-slider .clickimg-div.active-slide {
    padding: 5px;
}

.product-detail-wrapper {
    border-bottom: 1px solid gainsboro;
    padding-bottom: 10px;
}

.listing-bottom ul li {
    color: gray;
    margin-left: 15px;
}

.item-details-info {
    color: gray;
    text-align: justify;
}

.total-top-price {
    border-top: 1px solid gainsboro;
}

/*  ----------------------------- Discount cart ---------------------- */
.discount-cart {
    margin-left: 5px;
    border-radius: 15%;
    width: 10px;
    height: 5px;
    padding: 0px 3px;
    color: white;
    background-color: #2B9BFF;
    font-size: 0.8rem;
}

/*  ----------------------------- Available in stocks ---------------------- */
.product-title .quantity-in-stock {
    margin-left: 5%;
    border-radius: 5%;
    padding: 5px 13px;
    color: white !important;
    background-color: rgba(0, 128, 0, 0.575);
}

.out-of-stock-p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    content: "";
    background-color: #646464;
    color: #fff;
    padding: 2px 13px;
    font-size: 13px;
    z-index: 98;
}

/* .low-opacity {
    opacity: 0.6;
  } */

/* ================================   profile section start  ============================== */
.sidebar_bottom {
    color: #000;
    text-decoration: none;
    list-style: none;
}

.sidebar_listing {
    text-decoration: none;
    list-style: none;
}

.sidebar_listing li {
    padding: 11px 16px 11px 23px;
    border-bottom: 1px dashed gainsboro;
}

.side-profile-bar {
    width: 100%;
    height: 100%;
    background: #f8f5ff;
    border-radius: 11px;
    padding: 20px;
}

.sidebar-account {
    background-color: #f8f5ff;
    border-radius: 11px;
}

.my-acc-name {
    color: #7655ab;
    font-weight: 700;
}

.account-profile {
    border-bottom: 1px solid #7655ab;
    padding: 10px;
}

.logout {
    text-align: center;
    padding: 4px;
}

.num-color {
    color: gray;
}

.sidebar_listing i {
    margin-right: 11px;
}

.head-wrap-li {
    font-weight: 700;
    text-align: center;
}

/* ------------------      --------- cart-page-start===== */
.left-bar-card {
    position: relative;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dashed #c1c1c1;
    margin-bottom: 1rem;
}

.menu-crt {
    display: flex;
    width: 100%;
    height: 100%;
}

.menu-crt img {
    width: 80px;
    height: 80px;
}

.counter-crt {
    height: fit-content;
    padding: 3px 15px;
    border-radius: 5px;
    margin-right: 25px;
}

.name-bottom p {
    font-weight: 700;
}

.cart-heading {
    color: #000;
    font-weight: 700;
    font-size: 1.1rem;
    padding-bottom: 10px;
}

.left-cart {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .12);
    padding: 15px;
    background-color: #fff;
    border-radius: 8px;
}

.cart-page {
    background-color: #f5f1f7;
}

.total-item-crt {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gainsboro;

}

.select-item {
    box-shadow: 0 2px 10px rgba(0, 0, 0, .12);
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
}

.item-total p {
    font-size: 0.87rem;
    font-weight: 500;
    color: gray;
    margin-bottom: 0px;
    line-height: 1.5;
}

.price-size p {
    margin-bottom: 0;
    font-size: 0.87rem;
    font-weight: 500;
    line-height: 1.5;
}

.delivery-address {
    text-align: center;
    box-shadow: 0 2px 10px rgba(0, 0, 0, .12);
    background-color: #fff;
    padding: 15px;
    border-radius: 8px;
}

.btn-add {
    background: #7655ab !important;
    color: #fff !important;
    border: 1px solid #7655ab !important;
    width: 100%;
}

.delivery-address i {
    color: #ff3269;
    margin-right: 5px;
}

.paynow {
    width: 100%;
    padding: 3px 10px;
    background: #7655ab !important;
    color: #fff !important;
    border: 1px solid #7655ab !important;
}

/* ---------------------------     cheakout-page-start=================== */
.order-total img {
    max-width: 49px;
}

.order-total {
    display: flex;
}

.side-list {
    margin-left: 16px;
}

.order-bottom {
    padding: 10px;
    border-bottom: 1px solid gainsboro;
}

.sidebar-order {
    border: 1px solid gainsboro;
}

.order-heading {
    padding: 7px;
    background: #f6f2ff;
}

.payment-wrapper {
    box-shadow: 0 1px 7px rgba(0, 0, 0, .12);
    padding: 30px;
}

.billing-address .over-height {
    height: 300px;
    overflow-y: scroll;
}

.over-height .select-address :hover {
    background-color: #f0e7fffc;
}

.over-height .select-address .active {
    border-left: 3px solid #7655ab;
    background-color: #f0e7fffc;
}

.text-secondary_color {
    color: var(--primary);
}

/* =================------------           home-page-css============== */
.releted_product {
    border-bottom: 1px solid #e3dfea;
    padding-bottom: 6px;
}

.line_through_color {
    color: #8c8c8c;
    font-weight: 300;
    font-size: 14px;
}

.owl-crousel-section {
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    padding: 10px;
}

/* ------------- -----------   OWL CROUSEL CSS */
.owl-crousel-cart-img {
    width: 120px;
    height: 130px;
}

.owl-crousel-cart-main {
    justify-content: space-evenly;
}

.owl-crousel-cart-img img {
    height: 100%;
}

.owl-carousel .owl-nav button.owl-prev span {
    font-size: 35px;
}

.owl-carousel .owl-nav button span {
    font-size: 35px;
    line-height: 27px;
}

.owl-carousel .owl-nav button.owl-prev {
    position: absolute;
    top: 40%;
    left: -20px;
    width: 35px;
    height: 35px;
    border: 1px solid #7655ab !important;
    border-radius: 50%;
    font-weight: 600 !important;
    color: #fff !important;
    background-color: #7655ab !important;
}

.owl-carousel .owl-nav button.owl-next {
    position: absolute;
    top: 40%;
    right: -20px;
    width: 35px;
    height: 35px;
    border: 1px solid #7655ab !important;
    border-radius: 50%;
    font-weight: 600 !important;
    color: #fff !important;
    background-color: #7655ab !important;
}

.owl-nav .owl-prev:hover {
    background-color: #7655abbc !important;
}

.owl-nav .owl-next:hover {
    background-color: #7655abbc !important;
}

.owl-crousel-cart-price {
    color: var(--primary);
    transition: all .5s;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.2;
}

.owl-crousel-cart-name {
    width: 125px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

/* .owl-crousel-cart {
    display: grid;
    place-items: center;
} */

.login-left .login-without-token {
    width: 102px;
}

/*  ------------------------  ----    header dropdown toggle  ------------------------- */

#dropdown-basic {
    background-color: #c6baeb01 !important;
    border-color: #c6baeb12 !important;
    color: var(--primary) !important;
}

.show>.btn-primary.dropdown-toggle:focus {
    background-color: #c6baeb01 !important;
    border-color: #c6baeb12 !important;
    color: var(--primary) !important;
    box-shadow: none !important;
}

#dropdown-basic .btn-primary:focus,
.btn:focus {
    box-shadow: none !important;
    outline: none;
}

/* ------- --------------------  -----   MAP Model Style css  ---------------*/
.get-crrent-location-btn {
    margin: 0;
    padding: 0;
    color: green;
    font-weight: bold;
    border: 1px solid green;
    background-color: rgb(189, 245, 189);
}

.get-crrent-location-btn:hover {
    color: red;
}

.address-model {
    width: 100%;
    max-width: 800px;
    position: relative;
}

.Address-form label {
    display: block;
}

.address-close-btn {
    position: absolute;
    top: 10px;
    left: 765px;
}

.address-type-btn {
    box-sizing: border-box;
    appearance: none;
    background-color: transparent;
    border: 1px solid var(--primary);
    border-radius: 0.6em;
    color: var(--primary);
    cursor: pointer;
    display: flex;
    align-self: center;
    padding: 5px;
    text-decoration: none;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;

    &:hover,
    &:focus {
        color: #fff;
        outline: 0;
    }
}

.address-type-btn.active {
    background-color: #662eed;
    color: #fff;
}

.address-type-btn {
    transition: box-shadow 300ms ease-in-out, color 300ms ease-in-out;

    &:hover {
        box-shadow: 0 0 40px 40px #662eed inset;
    }
}


/*  --------------- ------    scroll button css    . ----------------- */
.scroll-button {
    position: fixed;
    right: 50px;
    bottom: 70px;
    z-index: 1;
    cursor: pointer;
    background-color: transparent;
    border: none;
    outline: none;
}

.scroll-button-icon {
    font-size: 3rem;
    transform: rotate(-90deg);
    color: var(--primary);
}

/*  --------------- ------    notify btn css    . ----------------- */
.notify-btn {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: var(--primary);
    padding: 3px 15px;
    letter-spacing: 2px;
    z-index: 98;
    color: #fff;
    border-top-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

/*  --------------- ------    favourite icon css    . ----------------- */
.favourite-icon {
    position: absolute;
    top: 3px;
    right: 10px;
    font-size: 20px;
    z-index: 98;
    color: var(--primary);
}

/*  --------------- ------    processing css    . ----------------- */

.processing-btn {
    width: 100%;
    color: #fff;
    background-color: var(--primary);
}

/*  --------------- ------    Loading css    . ----------------- */

.loader {
    display: flex;
    position: fixed;
    top: 47%;
    left: 45%;
    align-items: center;
    background-color: white;
    
}

.icon {
    height: 2.5rem;
    width: 2.5rem;
    animation: spin 1s linear infinite;
    stroke: rgba(107, 114, 128, 1);
}

.loading-text {
    font-size: 1.5rem;
    line-height: 1.5rem;
    font-weight: 500;
    color: rgba(107, 114, 128, 1);
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/*    -----------------================ user dashboard CSS ================------------------ */
.user-dashboard-subroute {
    list-style: none;
    padding: 8px 0;
}

.user-dashboard-subroute a {
    color: aliceblue;
    padding: 10px 0;
    margin-left: 50px;
}

.user-myorders img {
    width: 150px;
    height: 150px;
}

.user-AddAddress form label {
    display: block;
}

.user-details .list-unstyled a {
    color: #7e7e7e !important;
}

.user-name {
    box-shadow: 1px 0px 5px #ccc;
    padding: 7px 0px;
    background-color: #fff;
}

.use_dashboard .bg-light {
    box-shadow: 0px 0px 5px #Ccc;
}

.order_detail img {
    max-width: 100px;
}

.order_bottom_detail p {
    font-size: 14px;
}

.order_bottom_detail small {
    font-size: 12px !important;
}

.order_develvered_section {
    box-shadow: 0px 0px 3px #ccc;
    background-color: #fff;
    padding: 15px;
}

.delevery_time_order p {
    font-size: 14px;
}

.delevery_time_order small {
    font-size: 13px;
}

.use_dashboard {
    background-color: #f1f3f6;
}

.use_dashboard .user-details {
    background-color: #fff;
    box-shadow: 0px 0px 3px #ccc;
}

.use_dashboard .pro_img img {
    max-width: 130px;
    width: 100px;
    max-height: 130px;
    height: 100px;
    border-radius: 50%;
    object-fit: cover;
    border: 1px dashed #7655ab !important;
    padding: 3px;
}

.user_title_img img {
    max-width: 60px;
    width: 60px;
    max-height: 60px;
    height: 60px;
    border-radius: 50%;
    object-fit: cover;
}

.use_dashboard .change_icon {
    position: absolute;
    right: 0;
    bottom: 0;
    display: inline-block;
    color: var(--primary);
    cursor: pointer;
}

.use_dashboard .pro_img {
    display: inline-block;
    position: relative;
    margin-bottom: 20px;
}

.right_information {
    background-color: #fff;
    padding: 20px;
}

.change_icon_user {
    color: var(--primary);
}

.user_line {
    border: 1px solid gainsboro;
    background-color: gainsboro;
}

.user_line {
    border: 1px solid gainsboro;
    background-color: #fafafa;
    border-radius: 5px;
    padding: 8px 13px;
}

/*  ----------------------------------  order confirm -------------- */
.order-confirmation {
    background-color: #f9f1eb;
    padding: 40px;
    border-radius: 10px;
    font-family: 'Roboto', sans-serif;
}

.thank-you-section {
    padding-right: 40px;
}

.order-summary-section {
    padding-left: 40px;
}

.thank-you-section .order-confirm-billing-address p {
    display: flex;
    margin: 5px 0;
}

.order-confirm-billing-address p,
.order-summary p {
    margin: 0 0 10px;
}

.order-summary {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    border: 1px solid #ddd;
}

.order-items .item {
    display: flex;
    margin-bottom: 15px;
}

.order-items .item img {
    width: 70px;
    height: 70px;
    margin-right: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.order-items .details {
    flex: 1;
}

.summary-totals p {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.btn-danger {
    background-color: #ff6f61;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
}

.bg-secondary-btn-continue {
    background-color: var(--primary);
}

@media (max-width: 992px) {

    .owl-crousel-cart-price {
        flex-wrap: wrap;
        display: inline-flex;
    }

    .owl-carousel .owl-item img {
        max-width: 84px;
        object-fit: contain;
    }

    .dz-img-box.style-4 {
        width: 15rem;
    }

    .card-wrapper-cate .card-wrap {
        margin-right: 0px;
    }

    .card-wrapper-cate {
        gap: 6px;
    }

    .address-model {
        max-width: 700px;
        left: 52% !important;
    }

    .address-close-btn {
        top: 16px;
        left: 660px;
    }

    .location-header span {
        display: -webkit-box;
        width: 10rem;
        -webkit-line-clamp: 2;
    }

    .justify-content-between {
        padding-left: 0 !important;
    }

    .order_detail {
        display: block !important;
    }
}

@media (max-width: 767px) {
    .owl-carousel .owl-nav button.owl-next {
        right: -15px;
    }

    .owl-carousel .owl-nav button.owl-prev {
        left: -15px;
    }

    .order_detail {
        display: block !important;
    }

    .justify-content-between {
        padding-left: 0 !important;
    }

    .ReactModal__Content--after-open {
        width: 330px;
    }

    .dz-img-box.style-4 {
        width: 18rem;
    }

    .order-summary-section {
        padding: 0;
    }

    .user_img {
        width: 100%;
        max-width: 100px;
        height: auto;
    }

    .product-title .title-ptop {
        align-items: baseline;
    }

    .product-title .title-ptop i {
        color: #7655ab;
        font-size: 0.8rem;
    }

    .main-header {
        flex-wrap: wrap;
    }

    .main-header .navbar-logo {
        order: 1;
    }

    .main-header .search-location {
        order: 4;
    }

    .main-header .login-left {
        order: 2;
    }

    .nav-sec {
        background-color: #f8f5ff;
        transition: 0.7s;
        top: -100px;
        padding: 8px 1rem;
    }

    .categories-table__row {
        top: 140px;
    }

    .address-model {
        top: 95% !important;
        left: 53% !important;
        max-width: 348px;
    }

    .address-close-btn {
        left: 320px;
        margin-bottom: 20px;
    }

    .owl-crousel-cart {
        padding: px;
    }

    .slick-slider {
        margin-bottom: 1rem !important;
    }

    /* ------------------------- 360 header css -------------------- */
    .header-nav-sec .navbar {
        padding-top: 0.3rem !important;
        padding-bottom: 0 !important;
    }

    .location-header {
        order: 3;
    }

    .main-header {
        gap: 7px;
    }

    .search-location #scroll-text {
        animation: none;
    }

    .main-header .navbar-logo {
        width: 70px;
        height: 35px;
    }

    .search-location {
        transition: width 3s ease;
    }

    .login-left {
        gap: 0px;
    }

    .navbar-brand {
        padding: 0;
        margin-right: 0.5rem;
    }

    .main-header .navbar-brand {
        width: 75px;
    }

    .hero-play img {
        height: 180px;
    }

    .card-wrapper-cate .card-wrap {
        margin-right: 0px;
        width: 160px;
        height: auto;
    }

    .card-wrapper-cate {
        justify-content: center;
        gap: 15px;
    }

    .top-section-right {
        padding-left: 13px !important;
        margin-top: 8px;
    }

    .img-slider .clickimg-div {
        width: 60px;
        height: 65px;
    }

    .product-main-img {
        width: 200px;
        height: 200px;
    }

    .owl-crousel-cart-name {
        width: 110px;
    }

    .login-left i {
        font-size: 1.2rem;
    }

    .main-list p {
        font-size: 1.2rem;
    }

    .footer-category p {
        font-size: 1.2rem;
    }

    .footer-category .main-list {
        grid-template-columns: auto auto;
        gap: 0 0;
    }

    .scroll-button {
        right: 17px !important;
        bottom: 40px !important;
    }

    .loader {
        left: 26% !important;
    }

    .select-item {
        margin-top: 10px;
    }

    .menu-crt .name-crt-bottom {
        margin-left: 5px !important;
    }

    .menu-crt img {
        width: 75px;
        height: 75px;
    }

    .item-details-info {
        font-size: small;
    }

    .item-details-info {
        font-size: medium
    }

    /* ----------- product details page  */
    .categories-table__row .category-left-list {
        width: 85px;
        overflow-wrap: anywhere;
        display: block !important;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 5px 3px !important;
    }

    .categories-table__row .category-left-list p {
        margin: 0 !important;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
    }

    .categories-table__row .subcategory-left-list-img {
        width: 50px;
        height: 55px;
    }

    .cd-subcategory-item {
        padding-right: 30px;
    }

    .categories-table__row .subcategory-left-list-img img {
        margin-left: 13px !important;
        height: auto;
    }

    .categories-table__row .subcategory-sidebar {
        display: grid;
        place-items: center;
        gap: 5px;
    }

    .product-cart-item-name {
        font-size: 1rem;
        padding-right: 21px;
    }

    .dont-account-signup-login {
        font-size: 0.9rem;
    }

    .owl-crousel-cart-img {
        width: 100px;
        height: 110px;
    }

    .signup-form {
        width: 90%;
    }

    .login-form {
        max-width: 300px;
    }
}

@media (max-width: 567px) {
    .categories-table__row {
        top: 140px;
    }

    .address-model {
        top: 95% !important;
        left: 53% !important;
        max-width: 348px;
    }

    .address-close-btn {
        left: 320px;
        margin-bottom: 20px;
    }

    .owl-crousel-cart {
        padding: px;
    }

    .slick-slider {
        margin-bottom: 1rem !important;
    }

    /* ------------------------- 360 header css -------------------- */
    .search-location #scroll-text {
        animation: none;
    }

    .main-header .navbar-logo {
        width: 70px;
        height: 35px;
    }

    .search-location {
        transition: width 3s ease;
        /* width: 600px; */
        /* margin: 0 10px; */
        /* margin-left: 10px; */
    }

    .login-left {
        gap: 0px;
    }


    .navbar-brand {
        padding: 0;
        margin-right: 0.5rem;
    }

    .main-header .navbar-brand {
        width: 75px;
    }

    .hero-play img {
        height: 180px;
    }


    .top-section-right {
        padding-left: 13px !important;
        margin-top: 8px;
    }

    /* .checkout-left {
            justify-content: start !important;
        } */
    .img-slider .clickimg-div {
        width: 60px;
        height: 65px;
    }

    .product-main-img {
        width: 200px;
        height: 200px;
    }

    .owl-crousel-cart-name {
        width: 110px;
        /* font-size: 1.4rem; */
    }

    /*     ------------ 360 header  ------------- */
    .login-left i {
        font-size: 1.2rem;
    }

    /*     ------------ 360 footer  ------------- */
    .main-list p {
        font-size: 1.2rem;
    }

    .footer-category p {
        font-size: 1.2rem;
    }

    .footer-category .main-list {
        grid-template-columns: auto auto;
        gap: 0 0;
    }

    .scroll-button {
        right: 17px !important;
        bottom: 40px !important;
    }

    .loader {
        left: 26% !important;
    }

    .select-item {
        margin-top: 10px;
    }

    .menu-crt .name-crt-bottom {
        margin-left: 5px !important;
    }

    .menu-crt img {
        width: 75px;
        height: 75px;
    }

    .item-details-info {
        font-size: small;
    }

    .item-details-info {
        font-size: medium
    }

    /* ----------- product details page  */
    .categories-table__row .category-left-list {
        width: 85px;
        overflow-wrap: anywhere;
        display: block !important;
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
        padding: 5px 3px !important;
    }

    .categories-table__row .category-left-list p {
        margin: 0 !important;
        text-align: center;
        font-size: 11px;
        font-weight: 600;
    }

    .categories-table__row .subcategory-left-list-img {
        width: 50px;
        height: 55px;
    }

    .cd-subcategory-item {
        padding-right: 15px;
    }

    .categories-table__row .subcategory-left-list-img img {
        margin-left: 13px !important;
        height: auto;
    }

    .categories-table__row .subcategory-sidebar {
        display: grid;
        place-items: center;
        gap: 5px;
    }

    .product-cart-item-name {
        font-size: 1rem;
        padding-right: 21px;
    }

    .dont-account-signup-login {
        font-size: 0.9rem;
    }

    .owl-crousel-cart-img {
        width: 100px;
        height: 110px;
    }
}

/* =------======================= feedback start css --------------- */
.star {
    color: rgb(101, 91, 91);
}

.star.yellow {
    color: #7655ab;
}

.accordion-button {
    padding: 0.6rem 1.1rem !important;
}

/* ----------------------------------- Order Tracking css */

.tracking-wrapper {
    margin: 30px;
    padding: 0;
}

.tracking * {
    padding: 0;
    margin: 0;
}

.tracking {
    width: var(--size);
    max-width: 100%;
    position: relative;
}

.tracking .empty-bar {
    background: #ddd;
    position: absolute;
    width: 90%;
    height: 20%;
    top: 40%;
    margin-left: 5%;
}

.tracking .color-bar {
    background: var(--primary);
    position: absolute;
    height: 20%;
    top: 40%;
    margin-left: 5%;
    transition: all 0.5s;
}

.tracking ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
}

.tracking ul>li {
    background: #ddd;
    text-align: center;
    border-radius: 50%;
    z-index: 1;
    background-size: 70%;
    background-repeat: no-repeat;
    background-position: center center;
    transition: all 0.5s;
    display: inline-block;
    position: relative;
    width: 10%;
}

.tracking ul>li .el {
    position: relative;
    margin-top: 100%;
}

.tracking ul>li .el i {
    position: absolute;
    bottom: 100%;
    left: 17%;
    margin-bottom: 12%;
    color: #fff;
    font-size: 100%;
    display: none;
}

.tracking ul>li .txt {
    color: #999;
    position: absolute;
    top: 120%;
    left: -75%;
    text-align: center;
    width: 250%;
    font-size: .75rem;
}

.tracking .progress-2 .color-bar {
    width: 25%;
}

.tracking .progress-3 .color-bar {
    width: 60%;
}

.tracking .progress-4 .color-bar {
    width: 90%;
}

.tracking .progress-0>ul>li.bullet-1,
.tracking .progress-1>ul>li.bullet-1,
.tracking .progress-2>ul>li.bullet-1,
.tracking .progress-3>ul>li.bullet-1,
.tracking .progress-4>ul>li.bullet-1 {
    background-color: var(--primary);
}

.tracking .progress-2>ul>li.bullet-2,
.tracking .progress-3>ul>li.bullet-2,
.tracking .progress-4>ul>li.bullet-2 {
    background-color: var(--primary);
}

.tracking .progress-3>ul>li.bullet-3,
.tracking .progress-4>ul>li.bullet-3 {
    background-color: var(--primary);
}

.tracking .progress-4>ul>li.bullet-4 {
    background-color: rgb(88, 210, 88);
}

.tracking .progress-1>ul>li.bullet-1 .el i,
.tracking .progress-2>ul>li.bullet-1 .el i,
.tracking .progress-3>ul>li.bullet-1 .el i,
.tracking .progress-4>ul>li.bullet-1 .el i {
    display: block;
}

.tracking .progress-2>ul>li.bullet-2 .el i,
.tracking .progress-3>ul>li.bullet-2 .el i,
.tracking .progress-4>ul>li.bullet-2 .el i {
    display: block;
}

.tracking .progress-3>ul>li.bullet-3 .el i,
.tracking .progress-4>ul>li.bullet-3 .el i {
    display: block;
}

.tracking .progress-4>ul>li.bullet-4 .el i {
    display: block;
}

.tracking .progress-1>ul>li.bullet-1 .txt,
.tracking .progress-2>ul>li.bullet-1 .txt,
.tracking .progress-3>ul>li.bullet-1 .txt,
.tracking .progress-4>ul>li.bullet-1 .txt {
    color: var(--primary);
}

.tracking .progress-2>ul>li.bullet-2 .txt,
.tracking .progress-3>ul>li.bullet-2 .txt,
.tracking .progress-4>ul>li.bullet-2 .txt {
    color: var(--primary);
}

.tracking .progress-3>ul>li.bullet-3 .txt,
.tracking .progress-4>ul>li.bullet-3 .txt {
    color: var(--primary);
}

.tracking .progress-4>ul>li.bullet-4 .txt {
    color: var(--primary);
}

.controls {
    margin: 90px 30px 30px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.controls>div {
    display: flex;
    justify-content: flex-start;
    align-items: space-between;
    margin: 0;
    padding: 0;
}

.controls p,
.controls button {
    border: 0;
    line-height: 20px;
    padding: 15px;
    font-size: 0.8rem;
    text-transform: uppercase;
}

.controls button {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 6px;
    background: var(--primary);
    color: #fff;
    border-radius: 50px;
    transition: all .3s;
}

.controls button:nth-child(1) {
    margin-left: 0;
}

.controls button i {
    font-size: 1rem;
    margin: 0 5px;
}

.controls button#prev {
    padding-right: 30px;
}

.controls button#next {
    padding-left: 30px;
}

.controls button:hover,
.controls button:focus {
    outline: none;
    background-color: var(--dark);
}

/*======================  404 page =======================*/

.page_404 {
    padding: 40px 0;
    background: #fff;
    font-family: 'Arvo', serif;
}

.page_404 img {
    width: 100%;
}

.four_zero_four_bg {

    background-image: url(https://cdn.dribbble.com/users/285475/screenshots/2083086/dribbble_1.gif);
    height: 400px;
    background-position: center;
}

.four_zero_four_bg h1 {
    font-size: 80px;
}

.four_zero_four_bg h3 {
    font-size: 80px;
}

.link_404 {
    color: #fff !important;
    padding: 10px 20px;
    background: #39ac31;
    margin: 20px 0;
    display: inline-block;
}

.contant_box_404 {
    margin-top: -50px;
}



/*  - --------------------------- -------========= faqs ==== */
.main-accordion-button .accordion-button {
    font-size: 21px;
    font-weight: bold;
}

.sub-accordion-button .accordion-button {
    font-size: 17px;
    font-weight: 600;
}

.accordion-button:not(.collapsed) {
    color: #000;
    background-color: #fff;
    box-shadow: none;
}

.accordion-item {
    border: none;
}

.accordion-button:focus {
    box-shadow: none;
}

.srowsd {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    padding: 10px 20px;
    cursor: pointer;
}

.delevery_time_order {
    background-color: #4c00ff9c;
    padding: 3px 9px;
    display: inline-block;
    border-radius: 20px;
}

.delevery_time_order p {
    color: #fff;
    font-weight: 600;
}